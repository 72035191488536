.App {
  text-align: center;
  color: white;
    background: black;
  opacity: 0.95;

}
html {
    background: black;
  opacity: 0.95;

}

body{
      background: black;
  opacity: 0.95;

}